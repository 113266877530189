<template>
<div class="container">
    <label for="">List Channel Available</label>
    <div class="d-flex">
        <label :id="channel.name"  draggable @dragstart="drag($event)" for="" v-for="channel, index in listHierarki" :key="index" class="border p-3 ml-2"> {{channel.name}}</label>
    </div>
    <div class="text-center border p-2">
        <h2>Hierarki Channel</h2>
        <template v-if="isLoadingHierarki">
            <nested-hierarki :list-back-up="listChannelUsed" />
        </template>
    </div>
</div>
</template>
<script>
import draggable from 'vuedraggable'
import {mapState} from 'vuex'
import NestedHierarki from './NestedHierarki.vue'
export default {
    components:{
        draggable,
        NestedHierarki
    },
    computed: {
          ...mapState("NotifikasiStore", {
             listHierarki: state => state.hierarkiParent,
             isLoadingHierarki: state => state.isLoadingHierarki,
             listChannelUsed: state => state.listChannelUsed
         }),
    },
    methods:{
        allowDrop(ev) {
            ev.preventDefault();
        },
        drag(evt) {
            evt.dataTransfer.dropEffect = 'move'
            evt.dataTransfer.effectAllowed = 'move'
            evt.dataTransfer.setData("data", evt.target.id);
        },
    }
}
</script>