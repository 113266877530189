<template>
  <div class="d-flex justify-content-center" :class="lineBorder ? 'border-top-1': ''">
    <div class="row m-2 border-right-1" v-for="(item, index) in listBackUp" :key="index">
      <div class="col-12 text-center">
        <label class="border py-3 px-5 position-relative" :class="item.enabled ? '': 'bg-grey'">{{ item.channel }}  

            <!-- <i class="fa fa-times-circle position-absolute red-hover" @click="deleteChannel(index)"></i> -->
            <div class="position-absolute red-hover">
            <button type="button" class="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            </button>
            <div class="dropdown-menu dropdown-menu-right">
                <button class="dropdown-item" @click="deleteChannel(index)" type="button"><i class="fa fa-times-circle " ></i> Delete</button>
                <div class="dropdown-divider"></div>
                <button class="dropdown-item"  @click="changeEnabled(index)"  type="button">
                    <i :class="item.enabled ? 'fa fa-lock' : 'fa fa-unlock'" ></i> {{item.enabled ? 'Disabled': 'Enabled'}}</button>
            </div>
            </div>
        </label>
        <nested-hierarki
          v-if="item.backupChannel"
          :list-back-up="item.backupChannel"
          :line-border="true"
        />
        <template v-else>
          <div
            class="setUp mb-2"
            @drop="dropPlus($event, index)"
            @dragover="allowDrop($event)"
          >
            <label class="p-2">
                +
            </label>
            
          </div>
        </template>
      </div>
    </div>
    <div class="row m-2" v-if="listBackUp.length < 4">
      <div
        class="setUp"
        @drop="drop($event)"
        @dragover="allowDrop($event)"
      >
        <label class="p-2 mb-2">
                +
            </label>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
export default {
  name: "nested-hierarki",
  props: {
    listBackUp: {
      type: Array,
      default: () => [],
    },
    lineBorder: {
        type: Boolean,
        default: false
    }
  },
  methods: {
      changeEnabled(index) {
          this.setHierarki(false)
          this.listBackUp[index].enabled = !this.listBackUp[index].enabled
           setTimeout(()=> {
             this.setHierarki(true)
            }, 50)
      },
    ...mapMutations({
        setHierarki: 'NotifikasiStore/SET_LOADING_HIERARKI'
    }),
    drop(evt) {
      evt.preventDefault();
      var data = evt.dataTransfer.getData("data");
      if (this.listBackUp.findIndex((item) => item.channel == data) < 0) {
        this.setHierarki(false)
        const channel = { channel: data, enabled: true };
        this.listBackUp.push(channel);
      }
      setTimeout(()=> {
             this.setHierarki(true)
      }, 50)
    },
    // function for next backupChannel
    dropPlus(evt, index) { 
        var data = evt.dataTransfer.getData("data");
         const channel = { channel: data, enabled: true };
         if(this.listBackUp[index].channel != data) {  
            this.setHierarki(false)  
            this.listBackUp[index].backupChannel = []
            this.listBackUp[index].backupChannel.push(channel)
         }
         setTimeout(()=> {
             this.setHierarki(true)
         }, 50)
         
    },
    deleteChannel(index){
          this.setHierarki(false)
          this.listBackUp.splice(index, 1)
           setTimeout(()=> {
             this.setHierarki(true)
         }, 50)
    },
    allowDrop(ev) {
      ev.preventDefault();
    },
  },
};
</script>